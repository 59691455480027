import { FC } from 'react';
import IconProps from './IconProps';

const AddIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle cx="9.5" cy="9.5" r="7.5" fill="black" />
      </g>
      <path
        d="M9.93636 6.43751C9.93636 6.19588 9.74048 6.00001 9.49886 6.00001C9.25724 6.00001 9.06136 6.19588 9.06136 6.43751H9.93636ZM9.06136 9.5C9.06136 9.74162 9.25724 9.9375 9.49886 9.9375C9.74048 9.9375 9.93636 9.74162 9.93636 9.5H9.06136ZM9.93636 9.50002C9.93636 9.2584 9.74048 9.06252 9.49886 9.06252C9.25724 9.06252 9.06136 9.2584 9.06136 9.50002H9.93636ZM9.06136 12.5625C9.06136 12.8041 9.25724 13 9.49886 13C9.74048 13 9.93636 12.8041 9.93636 12.5625H9.06136ZM9.49853 9.0625C9.25691 9.0625 9.06104 9.25838 9.06104 9.5C9.06104 9.74163 9.25691 9.9375 9.49853 9.9375V9.0625ZM12.561 9.9375C12.8026 9.9375 12.9985 9.74163 12.9985 9.5C12.9985 9.25838 12.8026 9.0625 12.561 9.0625V9.9375ZM9.49804 9.9375C9.73966 9.9375 9.93554 9.74163 9.93554 9.5C9.93554 9.25838 9.73966 9.0625 9.49804 9.0625V9.9375ZM6.43555 9.0625C6.19392 9.0625 5.99805 9.25838 5.99805 9.5C5.99805 9.74163 6.19392 9.9375 6.43555 9.9375V9.0625ZM9.06136 6.43751V9.5H9.93636V6.43751H9.06136ZM9.06136 9.50002V12.5625H9.93636V9.50002H9.06136ZM9.49853 9.9375H12.561V9.0625H9.49853V9.9375ZM9.49804 9.0625H6.43555V9.9375H9.49804V9.0625Z"
        fill="white"
      />
      <defs>
        <filter id="filter0_d" x="0.875" y="0.875" width="18.75" height="18.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="0.75" dy="0.75" />
          <feGaussianBlur stdDeviation="0.9375" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0 0.768627 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default AddIcon;
